import * as React from "react";
import { connect } from "react-redux";

import { Site, Dimmer } from "tabler-react";

import withAuth from "../../utils/withAuth";
import HeaderUnLoggedUser from "./HeaderUnLoggedUser.react";
import HeaderLoggedUser from "./HeaderLoggedUser.react";

interface Props {
  auth: any;
  profileImage: any;
  isLoading: boolean;
}

class HeaderBrand extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const { isLoading, auth, profileImage } = this.props;

    const isAuthenticate = isLoading ? null : auth.loggedIn();
    const Profile = isLoading ? null : auth.getProfile();

    if (profileImage.status && Profile) {
      Profile.profilePic = profileImage.user[0].profile_image;
    }

    let button;
    if (isAuthenticate) {
      button = <HeaderLoggedUser userObj={Profile} />;
    } else {
      button = <HeaderUnLoggedUser />;
    }

    return (
      <Site.Header>
        <a href="/" className="brand-link">
          <img src="/static/images/logo.png" alt="all good jobs" height={35} />
          {/* <span className="logo">HireXL</span> */}
        </a>
        <div className="d-flex order-lg-2 ml-auto">
          <Dimmer active={isLoading}>{button}</Dimmer>
        </div>
      </Site.Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuthentication: state.auth,
    profileImage: state.profileImage,
  };
}

export default connect(mapStateToProps)(withAuth(HeaderBrand));
