import * as React from "react";
import { Text } from "tabler-react";

function FooterLogoAndAddress() {
  return (
    <React.Fragment>
      <a href="/" className="brand-link">
        <img
          src="/static/images/logo-white.jpg"
          alt="all good jobs"
          height={35}
        />
      </a>
      <Text style={{ marginTop: 10 }}>2nd floor, Plot no.4, Minarch Tower</Text>
      <Text> Sector 44, Gurugram</Text>
      <Text>Haryana - 122003</Text>
    </React.Fragment>
  );
}

export default FooterLogoAndAddress;
