import * as React from "react";
import { Grid } from "tabler-react";

const FooterCopyright = () => {
  return (
    <Grid.Row className="footer-copyright-wrapper">
      <Grid.Col width={6} className="text-left">
        Copyright © {new Date().getFullYear()}. All Good Jobs All Rights
        Reserved.
      </Grid.Col>
      <Grid.Col width={6} className="text-right">
        Designed & Developed by{" "}
        <a
          style={{ color: "#FFFFFF" }}
          target="_blank"
          href="https://technocube.co/"
        >
          Technocube(India)
        </a>
      </Grid.Col>
    </Grid.Row>
  );
};

export default FooterCopyright;
